import React from 'react'
import S3 from 'react-aws-s3'

const Upload = async file =>{
 
    const config = {
        bucketName: 'anikev2-ledco',
        dirName: 'local',
        region: 'us-east-2',
        accessKeyId: 'AKIATHD5FV2L274U3GSO',
        secretAccessKey: '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',
    }

    let ReactS3BucketClient = new S3(config);
    let location =  await ReactS3BucketClient.uploadFile(file, file.name)
    .then(e=>{
        if(e.status == 204){
            console.log('e.location', e.location)
            return e.location
        }
        else return null
    })
    .catch(()=> null)

    return location;
}

export default Upload;