import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getUserData } from "../../../app/UrlCrud";
import ReportPageForm from "./ReportPageForm";
import "./index.scss";

export default function ReportPage(props) {
    const [user, setUser] = useState(null);

    const [loading, setloading] = useState(false);
    const [isError, setisError] = useState(false);
    var pathArray = window.location.pathname.split("/");

    useEffect(() => {
        getUserData(pathArray[3])
            .then(({ data }) => {
                setUser(data);
                setloading(false);
            })
            .catch(error => {
                setloading(false);
                setisError(true);
            });
    }, []);

    return (
        <>
            <div className="reportContainer">
                <div className="logoSection">
                    <h3>ANIKE - Fault Portal</h3>
                    <img src={toAbsoluteUrl("/media/bg/bg-10.png")} />
                    <div style={{ paddingLeft: 40 }} className="date">
                        &copy; {new Date().getFullYear()} Anike
                    </div>
                </div>

                <div className="formSection">
                    <ReportPageForm id={pathArray[3]} />
                </div>
            </div>
        </>
    );
}
