import React from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import bg from "./error_401.png";
const CardWrapper = styled.div`
    .carde {
        display: flex;
        justify-content: center;
        img {
            width: 100%;
            max-width: 600px;
            height: auto;
        }
    }
`;
const UnAuthorised = () => {
    return (
        <CardWrapper>
            <Card>
                <div className="carde">
                    <img src={bg} alt="image" />
                </div>
            </Card>
        </CardWrapper>
    );
};

export default UnAuthorised;
