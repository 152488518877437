import { toast } from "react-toastify";
import { actions } from "../../src/app/modules/Auth/_redux/authRedux";

let notificationShown = false;

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    error => {
      // if user logged out or session expired
      if (error.response.status == 401) {
        //place your reentry code
        if (!notificationShown) {
          // TODO style success, danger and warning notifications
          toast.info("Session expired. Please login again", {
            autoClose: false
          });
          notificationShown = true;
        }
        store.dispatch(actions.logout());

        alert("unauthorized");
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
}
