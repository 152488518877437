import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import UnAuthorised from "./modules/UnAuthorised";
import { DashboardPage } from "./pages/DashboardPage";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const ManageUserPage = lazy(() =>
  import("./modules/ECommerce/pages/ManageUserPages")
);

const AssetPages = lazy(() => import("./modules/Assets/Pages"));
const SLAPages = lazy(() => import("./modules/ManageSLA/Pages"));

const CategoryPages = lazy(() => import("./modules/Categories/ManageCategory"));
const ServiceRequestPage = lazy(() => import("./modules/ServiceRequest/Pages"));
const WarehousePages = lazy(() => import("./modules/Warehouse/Pages"));

const PurchaseOrder = lazy(() =>
  import("./modules/PurchaseOrder/Pages")
);

const Pricebook = lazy(() => import("./modules/PriceBook"));

const MaterialRequest = lazy(() =>
  import("./modules/MaterialRequest/Pages")
);

const WorkOrder = lazy(() => import("./modules/WorkOrder/Pages"));

const Report = lazy(() => import("./modules/Report/Pages"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/user" component={ManageUserPage} />
        <Route path="/asset" component={AssetPages} />
        <Route path="/work-order" component={WorkOrder} />
        <Route path="/service-request" component={ServiceRequestPage} />
        <Route path="/manage-warehouse" component={WarehousePages} />
        <Route path="/sla" component={SLAPages} />
        <Route path="/pricebook" component={Pricebook} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/category" component={CategoryPages} />
        <Route path="/purchase-order" component={PurchaseOrder} />
        <Route path="/material-request" component={MaterialRequest} />
        <Route path="/report" component={Report} />
        <Route path="*" component={UnAuthorised} />
        <Redirect to="error/error-v1" />
        
      </Switch>
    </Suspense>
  );
}
