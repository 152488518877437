import axios from "axios";
export const GET_REPORTING_ASSET_URL = "/v2/asset/reporting";
export const REPORT_ASSET_URL = "/v2/fault/report";
export function requestAssets(values) {
  return axios.post(GET_REPORTING_ASSET_URL, { ...values });
}

export function reportFault(values) {
  return axios.post(REPORT_ASSET_URL, { ...values });
}

export function editFault(values) {
  return axios.post(`/v2/fault/edit/${values.id}`, { ...values });
}
