/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Redirect } from "react-router-dom";

export function DropdownMenu2() {
  return (
    <>
      {/*begin::Navigation*/}
      <ul className="navi navi-hover">
        <li className="navi-header pb-1">
          <span className="text-primary text-uppercase font-weight-bold font-size-sm">
            Create new:
          </span>
        </li>
        <li className="navi-item">
          <Link to="/asset/add" className="navi-link">
              <span className="navi-icon">
                <i className="flaticon2-delivery-truck"></i>
              </span>
              <span className="navi-text">Asset</span>
          </Link>
        </li>
        <li className="navi-item">
          <Link to="/asset/cluster/add" className="navi-link">
            <span className="navi-icon">
              <i className="flaticon2-layers"></i>
            </span>
            <span className="navi-text">Cluster</span>
          </Link>
        </li>
        <li className="navi-item">
          <Link to="/asset/bulk/add" className="navi-link">
            <span className="navi-icon">
              <i className="flaticon-upload"></i>
            </span>
            <span className="navi-text">Bulk upload Asset</span>
          </Link>
        </li>
        <li className="navi-item">
          <Link to="/asset/bulk/update" className="navi-link">
            <span className="navi-icon">
              <i className="flaticon-upload"></i>
            </span>
            <span className="navi-text">Update Asset Location Name</span>
          </Link>
        </li>
      </ul>
      {/*end::Navigation*/}
    </>
  );
}
