/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownMenu2 } from "../../../../_partials/dropdowns";

export function CreateAsset() {
  return (
    <>
      <Dropdown className="dropdown-inline pl-5" drop="down" alignRight>
        <Dropdown.Toggle
          id="dropdown-toggle-top2"
          variant="transparent"
          className="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle"
        >
          Create
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu2 />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
