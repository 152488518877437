import React from "react";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8
} from "../widgets";

import SaDashBoard from "./SaDashBoard";
import UserDashboard from "./UserDashboard";
import SupplierDashboard from "./SupplierDashboard";

import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

export function Demo1Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user, shallowEqual);

  const userdata = user && user.user;

  return (
    <>
      {userdata &&
        userdata.super ? <SaDashBoard />
        : userdata.supplier ? <SupplierDashboard />
        : (userdata.ao ||
          userdata.mro ||
          userdata.imo ||
          userdata.technician ||
          userdata.extTechnician) ? <UserDashboard /> : <UserDashboard /> }
    </>
  );
}
