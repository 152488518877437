import React, { useState, useEffect } from "react";
import {
  MixedWidget1_Supplier,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12
} from "../widgets";
import axios from "axios";

import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

export default function Demo1Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user, shallowEqual);
  const [dashboardInfo, setDashboardInfo] = useState(null);
  const userdata = user && user.user;
  useEffect(() => {
    axios.get("/v2/dashboard-supplier").then(({ data }) => {
      setDashboardInfo(data);
    });
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-xxl-4">
          <MixedWidget1_Supplier
            className="card-stretch gutter-b"
            dashboardInfo={dashboardInfo}
          />
        </div>
        <div className="col-lg-6 col-xxl-4">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
