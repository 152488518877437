import axios from "axios";

export const GET_USER_PROFILE_URL = `/v2/user/profile/`;
export const GET_USER_ROLES_URL = `/v2/role/assign`;
export const ADD_USER_URL = `/v2/user/create/`;
export const GET_CATEGORY_LIST_URL = "/v2/category";
export const GET_USER_ROLE_PERMISSION_URL = "/v2/role/permission";
export const GET_USER_DATA = "/v2/user/data";
export const GET_RECENT_ACTIVITIES_URL = "/v2/activities";

export function getUserProfile(userId) {
    return axios.get(`${GET_USER_PROFILE_URL}${userId}`);
}
export function getUserData(userId) {
    return axios.get(`${GET_USER_DATA}/${userId}`);
}

export function getUserRoles() {
    return axios.get(GET_USER_ROLES_URL);
}

export function addUser(value) {
    return axios.post(`${ADD_USER_URL}${value && value.userId}`, { ...value });
}

export function getCategoryList() {
    return axios.get(GET_CATEGORY_LIST_URL);
}

export function getUserRolesCreated(userId) {
    return axios.get(`/v2/role/createdBy`);
}

export function getUserRolePermission(id) {
    return axios.get(`${GET_USER_ROLE_PERMISSION_URL}/${id}`);
}

export function updateUserRolePermission(value) {
    return axios.post(`${GET_USER_ROLE_PERMISSION_URL}/${value.id}`, value);
}

export function getUserRecentActivities(userId) {
    return axios.get(`${GET_RECENT_ACTIVITIES_URL}/${userId}`);
}

export function getListOfUserAssignableToAsset() {
    return axios.get(`/v2/asset/assign`);
}

export function getListOfUserCreatedCluster() {
    return axios.get(`/v2/cluster/createdBy`);
}

export function createAsset(value) {
    return axios.post(`/v2/asset/create/`, value);
}

export function createCluster(value) {
    return axios.post(`/v2/cluster/create/`, value);
}

export function getListOfAssetCreatedBy() {
    return axios.get(`/v2/asset/created`);
}

export function createSLA(value) {
    return axios.post(`/v2/sla/create/`, value);
}

export function getSla(id) {
    return axios.get(`/v2/sla/${id}`);
}

export function editSLA(value) {
    return axios.put(`/v2/sla/update/${value._id}`, value);
}

export function closeSR(id) {
    return axios.get(`/v2/sr/close/${id}`);
}

export function markSrAsRaised(id) {
    return axios.get(`/v2/sr/raised/${id}`);
}

export function acceptSR(value) {
    return axios.post(`/v2/sr/sent/accept/${value._id}`, { ...value });
}

export function assignSR(value) {
    return axios.post(`/v2/sr/assign/${value._id}`, { ...value });
}

export function getAssignList() {
    return axios.get(`/v2/user/assign/list`);
}

export function closeWO(id) {
    return axios.get(`/v2/wo/close/${id}`);
}

export function approveWO(id) {
    return axios.post(`/v2/wo/approve/${id}`, { approve: true });
}

export function acceptWO(value) {
    return axios.post(`/v2/wo/sent/accept/${value._id}`, { ...value });
}

export function assignWO(value) {
    return axios.post(`/v2/wo/assign/${value._id}`, { ...value });
}

export function completeWO(value) {
    return axios.post(`/v2/wo/complete/${value._id}`);
}

export function certifyWO(value) {
    return axios.post(`/v2/wo/certify/${value._id}`, { ...value });
}

export function acceptObservation(value) {
    return axios.post(`/v2/observation/accept/${value._id}`, { ...value });
}

export function rejectObservation(value) {
    return axios.post(`/v2/observation/accept/${value._id}`, { ...value });
}

export function rateUser(value) {
    return axios.post(`/v2/user/rate-user/${value._id}`, { ...value });
}

export function closePO(id) {
    return axios.get(`/v2/purchase-order/close/${id}`);
}

export function acceptPO(value) {
    return axios.post(`/v2/purchase-order/sent/accept/${value._id}`, {
        ...value
    });
}

export function acceptMR(value) {
    return axios.post(`/v2/material-request/accept/${value.id}`, { ...value });
}

export function rejectMR(value) {
    return axios.post(`/v2/material-request/reject/${value.id}`, { ...value });
}

export function approvalList() {
    return axios.get(`/v2/approval/wo`);
}
export function approvalListPo() {
    return axios.get(`/v2/approval/po`);
}

export function createApproval(value) {
    return axios.post(`/v2/approval/create/wo`, { ...value });
}
export function createApprovalPO(value) {
    return axios.post(`/v2/approval/create/po`, { ...value });
}

export function editApproval(value) {
    return axios.post(`/v2/approval/update/${value._id}`, { ...value });
}

export function deleteApproval(value) {
    return axios.post(`/v2/approval/delete/${value}`);
}

export function getApproval() {
    return axios.get(`/v2/wo/sent/approval`);
}

export function getServiceProvider() {
    return axios.get(`/v2/user/service-provider`);
}

export function getAllScoreCard() {
    return axios.get(`/v2/score-card/all/list`);
}

export function getScore(value) {
    return axios.get(`/v2/score-card/${value}`);
}

export function getAssignedAsset() {
    return axios.get(`/v2/asset/assigned`);
}

export function getAssignedAssetDetails(value) {
    return axios.get(`/v2/asset/assigned-asset/details/${value}`);
}

export function getAssignedClusterDetails(value) {
    return axios.get(`/v2/asset/assigned-cluster/details/${value}`);
}
export function approvePO(id) {
    return axios.post(`/v2/purchase-order/approve/${id}`, { approve: true });
}
export function editUser(value) {
    return axios.put(`/v2/user/edit/${value._id}`, { ...value });
}

export function assgnAssetToTech(value) {
    return axios.post(`/v2/asset/technician/asset/assign/${value._id}`, {
        ...value
    });
}
export function assgnclusterToTech(value) {
    return axios.post(`/v2/asset/technician/cluster/assign/${value._id}`, {
        ...value
    });
}

export function getAssgnAssetToTech() {
    return axios.get(`/v2/asset/technician/assigned`);
}

export function createSrConfig(value) {
    return axios.post(`/v2/configuration/create`, { ...value });
}

export function updateSrConfig(value) {
    return axios.put(`/v2/configuration/update/${value.id}`, { ...value });
}


export function getSrConfig(value) {
    return axios.get(`/v2/configuration/created/sr`, { ...value });
}

export function getWoConfig(value) {
    return axios.get(`/v2/configuration/created/wo`, { ...value });
}