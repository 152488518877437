/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

import { useLocation } from "react-router-dom";

export function AuthPage() {
    let location = useLocation();

    return (
        <>
            <div className=" bg-white authContainer" id="kt_login">
                {/*begin::Aside*/}
                <div className="logoSection">
                    <img src={toAbsoluteUrl("/media/bg/bg-4.png")} />

                    <div className="opacity-70 font-weight-bold date">
                        &copy; {new Date().getFullYear()} Anike
                    </div>
                    {/*end: Aside Container*/}
                </div>
                {/*begin::Aside*/}

                {/* begin::Content body */}
                <div className="formSection">
                    <div className="authform">
                        <Switch>
                            <ContentRoute
                                path="/auth/login"
                                component={Login}
                            />
                            <ContentRoute
                                path="/auth/registration"
                                component={Registration}
                            />
                            <ContentRoute
                                path="/auth/forgot-password"
                                component={ForgotPassword}
                            />

                            <ContentRoute
                                path="/auth/reset-password/:token"
                                component={props => (
                                    <ResetPassword
                                        {...props}
                                        token={props.match.params.token}
                                    />
                                )}
                            />

                            <Redirect
                                from="/auth"
                                exact={true}
                                to="/auth/login"
                            />
                            <Redirect to="/auth/login" />
                        </Switch>
                    </div>
                </div>
                {/*end::Content*/}
            </div>
        </>
    );
}
