import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Select from "react-select";

import Dropzone, { useDropzone } from "react-dropzone";
import { Icon, List, ListItem } from "@wfp/ui";
import { iconUpload } from "@wfp/icons";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import stateList from "../../../../nigeria-state-and-lgas.json";
import { getCategoryList } from "../../../UrlCrud";

const initialValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  telephone: "",
  email: "",
  designation: "",
  address: "",
  category: [],
  subCategory: [],
  acceptTerms: false
};
const Form = styled.form`
  overflow-y: scroll;
  height: 400px;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #e6faff;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #00ccff;
    border-radius: 6px;
  }
`;
function Registration(props) {
  useEffect(() => {
    getCategoryList()
      .then(({ data }) => {
        setCategoryList(data);
      })
      .catch(error => {
        // setloading(false);
        setisError(true);
      });
  }, []);

  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState(0);
  const [stateValue, setState] = React.useState("");
  const [lgaValue, setLga] = React.useState("");

  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const [categoryList, setCategoryList] = useState([]);

  const [isError, setisError] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);

  function handleChangeEvent(event, newValue) {
    setValue(newValue);
  }

  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    lastName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    companyName: Yup.string(),
    designation: Yup.string(),
    telephone: Yup.number(),
    address: Yup.string(),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    acceptTerms: Yup.bool().required("You must accept the terms and conditions")
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const val = {
        ...values,
        state: {
          state: stateValue && stateValue.state,
          alias: stateValue && stateValue.alias
        },
        lga: lgaValue,
        category,
        subCategory,
        isCompany: values && values.companyName ? true : false
      };
      setSubmitting(true);
      enableLoading();
      register(val)
        .then(({ data }) => {
          setisSuccess(true);
          disableLoading();
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
          setisError(true);
          disableLoading();
        });
    }
  });

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone();

  const files = acceptedFiles.map(file => (
    <ListItem key={file.path}>
      {file.path} - {Math.round(file.size / 1000)} kB
    </ListItem>
  ));

  const className = classNames("wfp--dropzone__input", {
    "wfp--dropzone__input--drag-active": isDragActive,
    [className]: className
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeEvent}
          variant="fullWidth"
        >
          <Tab label="Register as Individual" />
          <Tab label="Register as Company" />
        </Tabs>
      </AppBar>

      <br />
      <br />

      <Form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {/* {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )} */}
        {/* end: Alert */}

        {/* begin::Alert */}
        {isError && (
          <div
            className="alert alert-custom alert-light-danger fade show mb-10"
            role="alert"
          >
            <div className="alert-icon">
              <span className="svg-icon svg-icon-3x svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                ></SVG>{" "}
              </span>
            </div>
            <div className="alert-text font-weight-bold">
              Error occurred while trying to register user. This is usually
              because the email already exist in the system. Please try again
              with another valid email.
            </div>
            <div className="alert-close" onClick={() => setisError(false)}>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="ki ki-close"></i>
                </span>
              </button>
            </div>
          </div>
        )}

        {isSuccess && (
          <div
            className="alert alert-custom alert-light-success fade show mb-10"
            role="alert"
          >
            <div className="alert-icon">
              <span className="svg-icon svg-icon-3x svg-icon-success">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                ></SVG>{" "}
              </span>
            </div>
            <div className="alert-text font-weight-bold">
              Registration successfully submited. A system adminstrator will
              contact you soon for approval of your account.
            </div>
            <div className="alert-close" onClick={() => setisSuccess(false)}>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="ki ki-close"></i>
                </span>
              </button>
            </div>
          </div>
        )}
        {/* end::Alert */}

        {value == 1 && (
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Company name"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "companyName"
              )}`}
              name="companyName"
              {...formik.getFieldProps("companyName")}
            />
            {formik.touched.companyName && formik.errors.companyName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.companyName}</div>
              </div>
            ) : null}
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="First name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "firstName"
            )}`}
            name="firstName"
            {...formik.getFieldProps("firstName")}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstName}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Last name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "lastName"
            )}`}
            name="lastName"
            {...formik.getFieldProps("lastName")}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastName}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Phone number"
            type="number"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "telephone"
            )}`}
            name="telephone"
            {...formik.getFieldProps("telephone")}
          />
          {formik.touched.telephone && formik.errors.telephone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.telephone}</div>
            </div>
          ) : null}
        </div>

        {value == 1 && (
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Designation"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "designation"
              )}`}
              name="designation"
              {...formik.getFieldProps("designation")}
            />
            {formik.touched.designation && formik.errors.designation ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.designation}</div>
              </div>
            ) : null}
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Address"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="address"
            {...formik.getFieldProps("address")}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.address}</div>
            </div>
          ) : null}
        </div>

        <Select
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "state"
          )}`}
          placeholder="Select State"
          closeMenuOnSelect={true}
          options={stateList}
          getOptionValue={option => option["alias"]}
          getOptionLabel={option => option["state"]}
          onChange={e => setState(e)}
          isDisabled={false}
          name="state"
          // type="text"
          // {...formik.getFieldProps("state")}
        />
        <br />

        <Select
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "lga"
          )}`}
          placeholder="LGA"
          closeMenuOnSelect={true}
          options={
            stateValue && stateValue.lgas.map(p => ({ name: p, label: p }))
          }
          name="lga"
          value={lgaValue}
          onChange={e => setLga(e)}
          getOptionValue={option => option["name"]}
          getOptionLabel={option => option["label"]}
          isDisabled={false}
        />
        <br />

        <Select
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "category"
          )}`}
          placeholder="Select Service Categories"
          closeMenuOnSelect={true}
          options={categoryList ? categoryList : []}
          getOptionValue={option => option["_id"]}
          getOptionLabel={option => option["name"]}
          isDisabled={false}
          isMulti={value === 1}
          onChange={e => {
            Array.isArray(e) ? setCategory(e) : setCategory([e]);
            setSubCategory([]);
          }}
        />
        <br />

        <Select
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "subcategory"
          )}`}
          placeholder="Select Service Sub Categories"
          closeMenuOnSelect={true}
          options={
            category
              ? category
                  .map(p => p.subCategory)
                  .flat()
                  .map(p => ({
                    label: p,
                    name: p
                  }))
              : []
          }
          getOptionValue={option => option["label"]}
          getOptionLabel={option => option["name"]}
          isDisabled={false}
          onChange={e => setSubCategory(e)}
          value={subCategory}
          isMulti={true}
        />
        <br />

        <div className="form-control form-control-solid h-auto py-5 px-6">
          <section className="wfp--dropzone">
            <div {...getRootProps({ isDragActive, className: className })}>
              <input {...getInputProps()} />
              <Icon className="wfp--dropzone__icon" icon={iconUpload} />
              <div>Drop files or click here to upload image</div>
            </div>
            <aside className="wfp--dropzone__file-list">
              <h4>Files</h4>
              <List>{files}</List>
            </aside>
          </section>
        </div>

        <br />
        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link to="#" className="mr-1">
              I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </Form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
