export const check = (rules, role, action, data) => {
  if(Array.isArray(action)){
    if(rules.some(r=> action.includes(r.value))){
      return true
    }
  }
  if (rules && rules.filter(p => p.value == action).length > 0) {
    return true;
  }
  return false;
};
