import React, { useState, useRef, useEffect } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { FormattedMessage, injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Card, FormControl } from "react-bootstrap";
import Select from "react-select";
// import { config } from "../../../config";
import Dropzone, { useDropzone } from "react-dropzone";
import { Icon, List, ListItem } from "@wfp/ui";
import { iconUpload } from "@wfp/icons";
import classNames from "classnames";
import "./index.scss";
import SVG from "react-inlinesvg";
import stateList from "../../../nigeria-state-and-lgas.json";
import { requestAssets, reportFault } from "./reportCrud";
import Upload from "../../Upload";
import { useLocation } from "react-router";
import LoadingAnimation from "../../../components/LoadingAnimation";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";


function ReportPageForm(props) {
    const location = useLocation();
    const history = useHistory();

    const { intl, id } = props;
    const [assets, setAssets] = useState([]);
    const [ cluster, setCluster] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isError, setisError] = useState(false);
    const [isSuccess, setisSuccess] = useState(false);
    const fileInput = useRef();
    const scrollup = useRef(null);


    const { user } = useSelector(state => state?.auth, shallowEqual);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`/v2/asset/asset-owner/cluster/${id}`)
            .then(({ data }) => {
                setLoading(false);
                const cluster = data.map(p => ({
                    ...p,
                    newName: p.clusterID || p.name
                }));

                // setAssets(assetOpt);
                setCluster(cluster)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, []);
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone();

    const executeScroll = () => scrollup.current.scrollIntoView();

    const files = acceptedFiles.map(file => (
        <div key={file.path}>
            {file.path} - {Math.round(file.size / 1000)} kB
        </div>
    ));

    const className = classNames("wfp--dropzone__input", {
        "wfp--dropzone__input--drag-active": isDragActive,
        [className]: className
    });

    return (
        <>
            {loading && <LoadingAnimation />}
            <div className="kt-login__body">
                <h2>{successMessage}</h2>
                <h2 style={{ color: "red" }}>{errorMessage}</h2>
                <div className="kt-login__form">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            email:
                                user?.user?.email ||
                                location?.state?.faultDetails
                                    ?.responsibleTechnician?.company?.email ||
                                "",
                            fullName:  user?.user ? `${user?.user?.firstName || ''} ${user?.user?.lastName || ''}` 
                                : location?.state?.faultDetails
                                    .responsibleTechnician.name || "",
                            phoneNumber: user?.user?.telephone || '',
                            lga: "",
                            state: "",
                            asset: location?.state?.faultDetails || "",
                            description: ""
                        }}
                        validate={values => {
                            setErrorMessage("");
                            const errors = {};

                            if (!values.email) {
                                errors.email = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                    values.email
                                )
                            ) {
                                errors.email = intl.formatMessage({
                                    id: "AUTH.VALIDATION.INVALID_FIELD"
                                });
                            }

                            if (!values.fullName) {
                                errors.fullName = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.phoneNumber) {
                                errors.phoneNumber = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.description) {
                                errors.description = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                        ) => {
                            setLoading(true);
                            let data = new FormData();
                            let doc = "";

                            if (values.document) {
                                doc = await Upload(values.document);

                                if (doc == null) {
                                    setSubmitting(false);
                                    setisError(true);
                                    setLoading(false);
                                    return;
                                }
                            }

                            const val = {
                                ...values,
                                faultType: values?.faultType?.name || null,
                                state: {
                                    state: values.state && values.state.state,
                                    alias: values.state && values.state.alias
                                },
                                document: [`${doc}`],
                                ...(values.asset.assetID && {
                                    asset: values.asset._id
                                }),
                                ...(values.asset.clusterID && {
                                    cluster: values.asset._id
                                })
                            };
                            setSubmitting(true);
                            reportFault(val)
                                .then(({ data }) => {
                                    setSubmitting(false);
                                    setisSuccess(true);
                                    resetForm({});
                                    setLoading(false);
                                    executeScroll();
                                })
                                .catch(error => {
                                    setSubmitting(false);
                                    setisError(true);
                                    setLoading(false);
                                });
                        }}
                    >
                        {({
                            values,
                            status,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            initialValues
                        }) => (
                            <Card className="card cardReverse ">
                                <div
                                    style={{ transform: "translateY(-40px)" }}
                                    ref={scrollup}
                                ></div>
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    autoComplete="off"
                                >
                                    {status && (
                                        <div
                                            role="alert"
                                            className="alert alert-danger"
                                        >
                                            <div className="alert-text">
                                                {status}
                                            </div>
                                        </div>
                                    )}

                                    <span
                                        className="kt-login__signup-label"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Fault Information 
                                    </span>

                                    {/* begin::Alert */}
                                    {isError && (
                                        <div
                                            className="alert alert-custom alert-light-danger fade show mb-10 mr-6 ml-6"
                                            role="alert"
                                        >
                                            <div className="alert-icon">
                                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Code/Info-circle.svg"
                                                        )}
                                                    ></SVG>{" "}
                                                </span>
                                            </div>
                                            <div className="alert-text font-weight-bold">
                                                Error occurred. Please try
                                                again.
                                            </div>
                                            <div
                                                className="alert-close"
                                                onClick={() =>
                                                    setisError(false)
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="alert"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">
                                                        <i className="ki ki-close"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {isSuccess && (
                                        <div
                                            className="alert alert-custom alert-light-success fade show mb-10 mr-6 ml-6"
                                            role="alert"
                                        >
                                            <div className="alert-icon">
                                                <span className="svg-icon svg-icon-3x svg-icon-success">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Code/Info-circle.svg"
                                                        )}
                                                    ></SVG>{" "}
                                                </span>
                                            </div>
                                            <div className="alert-text font-weight-bold">
                                                Fault report has been
                                                successfully submitted.
                                            </div>
                                            <div
                                                className="alert-close"
                                                onClick={() =>
                                                    setisSuccess(false)
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="alert"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">
                                                        <i className="ki ki-close"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    <br/><br/>
                                    <div className="form-group">
                                        <span>Select Cluster</span>
                                        <Select
                                            className="kt-width-full"
                                            label="Cluster"
                                            closeMenuOnSelect={true}
                                            value={values.clusterName}
                                            name="cluster"
                                            isDisabled={loading}
                                            options={cluster || []}
                                            getOptionValue={option =>
                                                option["_id"]
                                            }
                                            getOptionLabel={option =>
                                                option["newName"]
                                            }
                                            onChange={option => {
                                                setLoading(true);
                                                setFieldValue("cluster", option);
                                                axios
                                                .get(`/v2/asset/asset-owner/cluster-name/${option?._id}`)
                                                .then(({ data }) => {
                                                    const assetOpt = data.map(p => ({
                                                        ...p,
                                                        newName: `${p.assetID || ""} - ${p.name || ""} `
                                                    }));

                                                    setLoading(false);

                                                    setAssets(assetOpt);
                                                    setLoading(false);
                                                })
                                                .catch(error => {
                                                    setLoading(false);
                                                });
                                            }}
                                            // isDisabled={false}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <span>Select Location</span>
                                        <Select
                                            className="kt-width-full"
                                            label="Location"
                                            closeMenuOnSelect={true}
                                            value={values.locationID}
                                            name="locationID"
                                            isDisabled={loading}
                                            options={values?.cluster 
                                                ? [...new Set( assets?.filter((p)=> p?.clusterName?._id == values?.cluster?._id).map(p=> p?.locationName || p?.locationID ) )]
                                                .map(p=>({
                                                    name: p,
                                                    locationID: p
                                                }))
                                                : [] }
                                            getOptionValue={option =>
                                                option["locationID"]
                                            }
                                            getOptionLabel={option =>
                                                option["name"]
                                            }
                                            onChange={option => {
                                                setFieldValue("locationID", option);
                                            }}
                                            // isDisabled={false}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <span>Select Asset</span>
                                        <Select
                                            className="kt-width-full"
                                            label="Asset"
                                            closeMenuOnSelect={true}
                                            value={values.asset}
                                            name="asset"
                                            isDisabled={loading}
                                            options={
                                                values.locationID
                                                ? assets.filter(p=> 
                                                    (p?.locationID == values?.locationID?.name) ||
                                                    (p?.locationName == values?.locationID?.name)
                                                )
                                                : values.cluster
                                                ? assets.filter(p=> p?.clusterName?._id == values?.cluster?._id)
                                                : assets
                                            }
                                            getOptionValue={option =>
                                                option["_id"]
                                            }
                                            getOptionLabel={option =>
                                                option["newName"]
                                            }
                                            onChange={option => {
                                                setFieldValue("asset", option);
                                            }}
                                            // isDisabled={false}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <span>Fault Type</span>
                                        <Select
                                            className="kt-width-full"
                                            label="Fault Type"
                                            closeMenuOnSelect={true}
                                            value={values.faultType}
                                            name="faultType"
                                            isDisabled={loading}
                                            options={
                                                [{
                                                    name: 'Repair',
                                                    label: 'Repair'
                                                }, {
                                                    name: 'Replacement',
                                                    label: 'Replacement'
                                                }]
                                            }
                                            getOptionValue={option =>
                                                option["name"]
                                            }
                                            getOptionLabel={option =>
                                                option["label"]
                                            }
                                            onChange={option => {
                                                setFieldValue("faultType", option);
                                            }}
                                            // isDisabled={false}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <span>Fault description</span>
                                        <FormControl
                                            as="textarea"
                                            type="address"
                                            margin="normal"
                                            label="description"
                                            className="kt-width-full"
                                            name="description"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                            helperText={
                                                touched.description &&
                                                errors.description
                                            }
                                            error={Boolean(
                                                touched.description &&
                                                    errors.description
                                            )}
                                        />
                                    </div>

                                    <div className="form-control form-control-solid h-auto py-3 px-6">
                                        <section className="wfp--dropzone">
                                            <div
                                                {...getRootProps({
                                                    isDragActive,
                                                    className: className
                                                })}
                                            >
                                                <input
                                                    style={{
                                                        opacity: 0,
                                                        position: "relative",
                                                        zIndex: 2,
                                                        cursor: "pointer"
                                                    }}
                                                    name="file"
                                                    accept=".png, .jpg, .jpeg"
                                                    // onChange={handleChange}
                                                    // value={values.document}
                                                    ref={fileInput}
                                                    type="file"
                                                    onChange={e => {
                                                        setFieldValue(
                                                            "document",
                                                            e.target.files[0]
                                                        );
                                                    }}
                                                    className="w-100 mb-2"
                                                />
                                                <div>
                                                    {values.document?.name !==
                                                    undefined
                                                        ? values.document?.name
                                                        : "Drop files or click here to upload image"}
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                 
                                    <br /><br />

                                    <div className="kt-login__divider">
                                        <div className="kt-divider">
                                            <span />
                                            <span />
                                        </div>
                                    </div>

                                    <span
                                        className="kt-login__signup-label"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        User Information
                                    </span>
                                    <br />
                                    <div className="form-group mb-0">
                                        <TextField
                                            margin="normal"
                                            label="Full name"
                                            className="kt-width-full"
                                            name="fullName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.fullName}
                                            helperText={
                                                touched.fullName &&
                                                errors.fullName
                                            }
                                            error={Boolean(
                                                touched.fullName &&
                                                    errors.fullName
                                            )}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <TextField
                                            label="Email"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            helperText={
                                                touched.email && errors.email
                                            }
                                            error={Boolean(
                                                touched.email && errors.email
                                            )}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <TextField
                                            type="number"
                                            label="Phone number"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="phoneNumber"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.phoneNumber}
                                            helperText={
                                                touched.phoneNumber &&
                                                errors.phoneNumber
                                            }
                                            error={Boolean(
                                                touched.phoneNumber &&
                                                    errors.phoneNumber
                                            )}
                                        />
                                    </div>
                                    <br />

                                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                        <Link
                                            to="/auth"
                                            className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`}
                                        >
                                            Back
                                        </Link>
                                        {
                                            user?.user ? (
                                                <div
                                                    // id="kt_login_signin_submit"
                                                    // type="submit"
                                                    disabled={isSubmitting}
                                                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                                                    onClick={async ()=>{
                                                        let doc = "";

                                                        if (values.document) {
                                                            doc = await Upload(values.document);
                            
                                                            if (doc == null) {
                                                                // setSubmitting(false);
                                                                // setisError(true);
                                                                // setLoading(false);
                                                                return;
                                                            }
                                                        }

                                                        const val = {
                                                            ...values,
                                                            faultType: values?.faultType?.name || null,
                                                            // asset: values?.asset?._id,
                                                            state: {
                                                                state: values.state && values.state.state,
                                                                alias: values.state && values.state.alias
                                                            },
                                                            document: [`${doc}`],
                                                            // ...(values.asset.assetID && {
                                                            //     asset: values.asset._id
                                                            // }),
                                                            // ...(values.asset.clusterID && {
                                                            //     cluster: values.asset._id
                                                            // })
                                                        };

                                                        history.push('/service-request/fault', {
                                                            fault: val
                                                        });
                                                    }}
                                                >
                                                    <span>Next</span>
                                                </div>
                                            ) : (
                                                <button
                                                    id="kt_login_signin_submit"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                                                >
                                                    <span>Submit</span>
                                                    {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                                                </button>
                                            )
                                        }
                                    </div>
                                </form>
                            </Card>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default injectIntl(connect(null, null)(ReportPageForm));
