/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import Can from "../../../../../_metronic/Can";

export function AsideMenuList({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
                  "menu-item-active"} menu-item-open menu-item-not-hightlighted`
            : "";
    };

    const { user } = useSelector(state => state.auth.user, shallowEqual);

    const permissions = user && user.userRole ? user.userRole.permission : [];
    const userRole = user && user.userRole ? user.userRole.name : null;

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/dashboard",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Design/Layers.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">Dashboard</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/* Manage User */}
                {/*begin::1 Level*/}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="manageUser"
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/user/add",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/user/add"
                            >
                                <i className="svg-icon menu-icon fa far fa-user"></i>
                                <span className="menu-text">Manage User</span>
                            </NavLink>
                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Manage User
                                            </span>
                                        </span>
                                    </li>
                                    {/*begin::2 Level*/}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="createUser"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/user/add"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/user/add"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Add User
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="manageUser"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/user/view"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/user/view"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        View User
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    {/*end::2 Level*/}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewUserRoles"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/user/roles/create"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/user/roles/create"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Create role
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewUserRoles"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/user/role"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/user/role"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        User Roles
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    {/*begin::2 Level*/}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="manageUserRegistration"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/user/request"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/user/request"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Registration Request
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    {/*end::2 Level*/}
                                </ul>
                            </div>
                        </li>
                    )}
                />

                {/* Manage Asset section */}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="manageAsset"
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/asset/view",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/asset/view"
                            >
                                <i className="svg-icon menu-icon flaticon2-delivery-truck"></i>
                                <span className="menu-text">Manage Asset</span>
                            </NavLink>

                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Manage Asset
                                            </span>
                                        </span>
                                    </li>

                                    {/* Create new Asset */}
                                    {/* <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="createNewAsset"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/asset/add"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/asset/add"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Create new Asset
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    /> */}

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewAsset"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/asset/view"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/asset/asset-list"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        View Asset
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    {/* Create new cluster */}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewAsset"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/asset/cluster"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/asset/cluster"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        View Cluster
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                </ul>
                            </div>
                        </li>
                    )}
                />

                {/* assign asssets */}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="viewAssignedAsset" 
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/asset/assigned",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/asset/assigned"
                            >
                                <i className="svg-icon menu-icon flaticon2-delivery-truck"></i>
                                <span className="menu-text">
                                    Assigned Asset
                                </span>
                            </NavLink>

                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Assigned Asset
                                            </span>
                                        </span>
                                    </li>
                                    {/* assigned assets for mro */}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewOrganizationAssignedAsset"
                                        yes={() => (
                                            <li
                                                className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                    "/asset/assigned",
                                                    true
                                                )}`}
                                                aria-haspopup="true"
                                                data-menu-toggle="hover"
                                            >
                                                <NavLink
                                                    className="menu-link menu-toggle"
                                                    to="/asset/assigned"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Organization Asset
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    {/* assigned assets for teach */}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewTechAssignedAsset"
                                        yes={() => (
                                            <li
                                                className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                    "/asset/assigned/technician",
                                                    true
                                                )}`}
                                                aria-haspopup="true"
                                                data-menu-toggle="hover"
                                            >
                                                <NavLink
                                                    className="menu-link menu-toggle"
                                                    to="/asset/assigned/technician"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Assigned
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                </ul>
                            </div>
                        </li>
                    )}
                />

                {/* Manage SLA section */}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="manageAsset"
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/asset/view",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/sla/manage"
                            >
                                <i className="svg-icon menu-icon fab fa-wpforms"></i>
                                <span className="menu-text">Manage SLA</span>
                            </NavLink>
                        </li>
                    )}
                />

                {/* Manage Category section  */}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="manageCategory"
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/category/view",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/category/view
            "
                            >
                                <i className="svg-icon menu-icon flaticon2-folder"></i>
                                <span className="menu-text">
                                    Manage Category
                                </span>
                            </NavLink>

                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Manage Category
                                            </span>
                                        </span>
                                    </li>

                                    {/* Create new Category */}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="createCategory"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/category/add"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/category/add"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Add Category
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    {/* View Category */}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="manageCategory"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/category/view"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/category/view"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        View Categories
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                </ul>
                            </div>
                        </li>
                    )}
                />

                {/* Service request section */}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="viewSR"
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/service-request",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/service-request"
                            >
                                <i className="svg-icon menu-icon fas fa-hand-holding-water"></i>
                                <span className="menu-text">
                                    Service Request
                                </span>
                            </NavLink>

                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Service Request
                                            </span>
                                        </span>
                                    </li>

                                    {/* Create new Asset */}
                                    {/* <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="createSR"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/service-request"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/service-request/send"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Send SR
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    /> */}

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewOutgoingSR"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/service-request/outgoing"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/service-request/outgoing"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Outgoing
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewIncomingSR"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/service-request/incoming"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/service-request/incoming"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Incoming
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewCompletedSR"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/service-request/completed"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/service-request/completed"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Completed
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="manageObservation"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/service-request/observation"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link menu-toggle"
                                                    to="/service-request/observation"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Observation
                                                    </span>
                                                </NavLink>

                                                <div className="menu-submenu">
                                                    <i className="menu-arrow" />
                                                    <ul className="menu-subnav">
                                                        <li
                                                            className="menu-item menu-item-parent"
                                                            aria-haspopup="true"
                                                        >
                                                            <span className="menu-link">
                                                                <span className="menu-text">
                                                                    Observation
                                                                </span>
                                                            </span>
                                                        </li>

                                                        <Can
                                                            rules={permissions}
                                                            userRole={userRole}
                                                            perform="viewSentObservation"
                                                            yes={() => (
                                                                <li
                                                                    className={`menu-item ${getMenuItemActive(
                                                                        "/service-request/observation/sent"
                                                                    )}`}
                                                                    aria-haspopup="true"
                                                                >
                                                                    <NavLink
                                                                        className="menu-link"
                                                                        to="/service-request/observation/sent"
                                                                    >
                                                                        <i className="menu-bullet menu-bullet-dot">
                                                                            <span />
                                                                        </i>
                                                                        <span className="menu-text">
                                                                            Sent
                                                                        </span>
                                                                    </NavLink>
                                                                </li>
                                                            )}
                                                        />
                                                    </ul>
                                                </div>
                                            </li>
                                        )}
                                    />
                                </ul>
                            </div>
                        </li>
                    )}
                />

                {/* Manage Work order section */}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="manageWO"
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/work-order/view",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/work-order/view
            "
                            >
                                <i className="svg-icon menu-icon fas fa-file-contract"></i>
                                <span className="menu-text">Work Order</span>
                            </NavLink>

                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Work Order
                                            </span>
                                        </span>
                                    </li>

                                    {/* Create new Category */}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="woConfiguration"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/work-order/configuration"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/work-order/configuration"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Configuration
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="approveWO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/work-order/approval-requests"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/work-order/approval-requests"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Approval Requests
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
{/* 
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="createWO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/work-order/send"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/work-order/send"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Raise WO
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    /> */}

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewOutgoingWO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/work-order/outgoing"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/work-order/outgoing"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Outgoing
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewIncomingWO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/work-order/incoming"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/work-order/incoming"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Incoming
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewCompletedWO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/work-order/completed"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/work-order/completed"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Completed
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewCompletedWO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/work-order/certified"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/work-order/certified"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Certified
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                </ul>
                            </div>
                        </li>
                    )}
                />


                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform={["manageAssetFault", "viewAsset", "viewAssignedAsset" ]}
                    yes={() => (
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/asset/reported-faults"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/asset/reported-faults"
                            >
                                <i className="svg-icon menu-icon fas fa-book-dead"></i>
                                <span className="menu-text">Reported Faults</span>
                            </NavLink>
                        </li>
                    )}
                />

                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="manageAssetFault"
                    yes={() => (
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/asset/report-fault"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/asset/report-fault"
                            >
                                <i className="svg-icon menu-icon fas fa-book-dead"></i>
                                <span className="menu-text">Fault Portal</span>
                            </NavLink>
                        </li>
                    )}
                />

                {/*Manage warehouse*/}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="manageWarehouse"
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/manage-warehouse/view",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/manage-warehouse/view"
                            >
                                <i className="svg-icon menu-icon fas fa-house-damage"></i>
                                <span className="menu-text">Warehouse</span>
                            </NavLink>

                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Warehouse
                                            </span>
                                        </span>
                                    </li>
                                    {/* <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="addWarehouse"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/manage-warehouse"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/manage-warehouse/add"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Add Warehouse
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    /> */}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewWarehouse"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/manage-warehouse"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/manage-warehouse/view"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        View Warehouses
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                </ul>
                            </div>
                        </li>
                    )}
                />

                {/* Purchase Order order section */}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="managePO"
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/purchase-order/view",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/purchase-order/view"
                            >
                                <i className="svg-icon menu-icon fas fa-cart-plus"></i>
                                <span className="menu-text">
                                    Purchase Order
                                </span>
                            </NavLink>

                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Purchase Order
                                            </span>
                                        </span>
                                    </li>

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewOutgoingPO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/purchase-order/outgoing"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/purchase-order/outgoing"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Outgoing
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewIncomingPO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/purchase-order/incoming"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/purchase-order/incoming"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Incoming
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewCompletedPO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/purchase-order/completed"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/purchase-order/completed"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Completed
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="poConfiguration"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/purchase-order/configuration"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/purchase-order/configuration"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Configuration
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="approvePO"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/purchase-order/approval"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/purchase-order/approval"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Approval Request
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="confirmSupply"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/purchase-order/confirm/supply"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/purchase-order/confirmation/supply"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Supply Confirmation
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />
                                </ul>
                            </div>
                        </li>
                    )}
                />

                {/* Pricebook*/}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="managePricebook"
                    yes={() => (
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/pricebook"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/pricebook/view"
                            >
                                <i className="svg-icon menu-icon fas fa-book"></i>
                                <span className="menu-text">Pricebook</span>
                            </NavLink>
                        </li>
                    )}
                />

                {/* report */}
                <Can
                    rules={permissions}
                    userRole={userRole}
                    perform="manageReport"
                    yes={() => (
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                "/report",
                                true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                        >
                            <NavLink
                                className="menu-link menu-toggle"
                                to="/report"
                            >
                                <i className="svg-icon menu-icon fas fa-chart-pie"></i>
                                <span className="menu-text">Report</span>
                            </NavLink>

                            <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                    <li
                                        className="menu-item menu-item-parent"
                                        aria-haspopup="true"
                                    >
                                        <span className="menu-link">
                                            <span className="menu-text">
                                                Report
                                            </span>
                                        </span>
                                    </li>

                                    {/* Asset report */}
                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewAssetReport"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/report/asset"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/report/asset"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Asset Report
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewAllSRReport"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/report/sr-wo-report"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/report/sr-wo-report"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        SR/WO Report
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewWarehouseReport"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/report/warehouse"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/report/warehouse"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Warehouse Report
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewScoreCard"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/report/score-card"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/report/score-card"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        Score Card
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />

                                    <Can
                                        rules={permissions}
                                        userRole={userRole}
                                        perform="viewOwnScoreCard"
                                        yes={() => (
                                            <li
                                                className={`menu-item ${getMenuItemActive(
                                                    "/report/my-score-card"
                                                )}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink
                                                    className="menu-link"
                                                    to="/report/my-score-card"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">
                                                        CMC Score Card
                                                    </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    />


                                </ul>
                            </div>
                        </li>
                    )}
                />
            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
