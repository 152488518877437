/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu1 } from "../../dropdowns";
import { getUserRecentActivities } from "../../../../app/UrlCrud";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";

export function ListsWidget9({ className }) {
  const [activities, setActivity] = useState([]);

  const userData = useSelector(state => state.auth.user.user, shallowEqual);

  useEffect(() => {
    getUserRecentActivities(userData && userData._id)
      .then(({ data }) => {
        setActivity(data);
      })
      .catch(error => {
        console.log("Error while loading recent activities");
      });
  }, [userData]);

  return (
    <>
      <div className={`card card-custom  ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              Recent Activity
            </span>
          </h3>
          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
              >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu1 />
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
        {/* Body */}
        <div className="card-body pt-4">
          <div className="timeline timeline-6 mt-3">
            {activities.map((p, index) => (
              <div className="timeline-item align-items-start" key={p._id}>
                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                  {moment(p.createdAt).format("h:mm:ss a")}
                </div>

                <div className="timeline-badge">
                  <i
                    className={`fa fa-genderless ${
                      index % 3 == 0
                        ? "text-primary"
                        : index % 2 == 0
                        ? "text-warning"
                        : "text-danger"
                    }  
                        icon-xl`}
                  ></i>
                </div>

                <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
                  {p.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
