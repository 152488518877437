import React from "react";
import { Card, CardBody } from "../_metronic/_partials/controls";
import styled from "styled-components";

const Modal = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(25, 33, 43, 0.4);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;
const Spinner = styled.div`
    & {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        border: 2px solid #3699ff;
        border-right: 2px solid transparent;
        animation: spin 0.5s linear infinite;
        margin: 0 auto;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

export default function LoadingAnimation({ info }) {
    return (
        <Modal>
            <Card>
                <CardBody style={{ padding: "1rem" }}>
                    <Spinner></Spinner>
                    {info && (
                        <div style={{ fontSize: "11px", marginTop: ".5rem" }}>
                            {info}
                        </div>
                    )}
                </CardBody>
            </Card>
        </Modal>
    );
}
